import logger from 'logger'
import type { VolumeUnit } from 'typings/graphql'
import formatProductLabelsForAnalytics from './formatProductLabelsForAnalytics'


type GetQueueAddItemProductProps = {
  intl: Intl.Manager // for labels
  // eslint-disable-next-line max-len
  product: Pick<ProductFragment.Base, 'id' | 'uid' | 'type' | 'image' | 'rebrandImage' | 'name' | 'brandInfo' | 'category' | 'gender' | 'upchargePrice' | 'theEdit' | 'isSubscribedToStockNotification'>
  // force tradingItem selection, we require only some fields
  tradingItem: Pick<TradingItemFragment.Base, 'id' | 'uid' | 'section' | 'isLimitedEdition' | 'volume' | 'productGroup' | 'type'>
  isDrift?: boolean
  isStarterSet?: boolean
  // easy way to override some fields
  override?: Partial<QueueModule.AddItemProduct>
}

// check if volume is exact unit and size
export const isExactVolume = (info: { volume: number, unit: VolumeUnit }, volume: number, unit: VolumeUnit): boolean => {
  return info?.volume === volume && info?.unit === unit
}

export const getIsVial = (tradingItem: Pick<TradingItemFragment.Base, 'productGroup' | 'type'>): boolean => {
  const { productGroup, type } = tradingItem || {}

  return (
    productGroup === 'Perfume'
    // not full-size bottle
    && type !== 'PerfumeBottle'
  )
}

export const getIsTravelSizeVial = (tradingItem: Pick<TradingItemFragment.Base, 'volume' | 'productGroup' | 'type'>): boolean => {
  const { volume } = tradingItem || {}

  return (
    getIsVial(tradingItem)
    && (
      isExactVolume(volume, 0.27, 'oz')
      || isExactVolume(volume, 8, 'ml')
    )
  )
}

export const getIs1_5mlVial = (tradingItem: Pick<TradingItemFragment.Base, 'volume' | 'productGroup' | 'type'>): boolean => {
  const { volume } = tradingItem || {}

  return getIsVial(tradingItem) && isExactVolume(volume, 1.5, 'ml')
}


// you should pass selected trading item
export const getAddItemProduct = (props: GetQueueAddItemProductProps): QueueModule.AddItemProduct => {
  const {
    intl,
    product,
    tradingItem,
    override,
    isDrift = false,
    isStarterSet = false,
  } = props

  const {
    id,
    uid,
    brandInfo: { name: brand },
    category,
    gender,
    image,
    isSubscribedToStockNotification,
    name,
    rebrandImage,
    theEdit,
    upchargePrice,
  } = product

  const { section } = tradingItem || {}
  const isLimitedDrop = section === 'LimitedDrop'

  const isVial = getIsVial(tradingItem)
  const isTravelSizeVial = getIsTravelSizeVial(tradingItem)
  const is1_5mlVial = getIs1_5mlVial(tradingItem)
  const isRefill = product.type === 'CarFreshenerRefill' && !isStarterSet

  return {
    productId: id,
    productUid: uid,
    tradingItemId: tradingItem?.id,
    tradingItemUid: tradingItem?.uid,
    name,
    brand,
    category,
    gender,
    image,
    rebrandImage,
    productLabels: formatProductLabelsForAnalytics({
      product,
      intl,
    }),
    upchargePrice,
    isLimitedEdition: tradingItem?.isLimitedEdition,
    isSubscribedToStockNotification,
    theEdit,
    isLimitedDrop,
    isDrift,
    isStarterSet,
    isVial,
    isTravelSizeVial,
    is1_5mlVial,
    isRefill,
    ...override,
  }
}

type GetTradingItemProps = {
  product: Pick<ProductFragment.Base, 'tradingItems'>
  isEcommerce?: boolean // prioritize ecommerce
  isFullSizeBottlePrioritized?: boolean // prefer full bottle
}

// helps to get required trading item in Product Card
export const getTradingItem = (props: GetTradingItemProps) => {
  const { product, isEcommerce, isFullSizeBottlePrioritized } = props

  if (!product) {
    logger.error('getTradingItem was called without a product')

    return {
      tradingItem: null,
      isEcommerceProduct: false,
    }
  }

  const { tradingItems } = product

  const subscriptionTradingItem = tradingItems?.items?.find(({ section }) => section === 'Subscription')
  const regularEcommerceTradingItem = tradingItems?.items
    // ATTN we shouldn't allow to add course kits directly
    .find(({ section }) => section !== 'Subscription')


  const fullSizeBottleTradingItem = tradingItems?.items?.findLast(({
    section,
    type,
  }) => section === 'Ecommerce' && type === 'PerfumeBottle')

  /*
  * FullSize bottle has the highest priority.
  * Then depending on isEcommerce it can be ecommerce item or subscription item.
  * For product cards isEcommerce is false
  * */
  const tradingItem = (
    (isFullSizeBottlePrioritized && fullSizeBottleTradingItem)
    || (isEcommerce ? regularEcommerceTradingItem : subscriptionTradingItem || regularEcommerceTradingItem)
  )

  const isEcommerceProduct = tradingItem?.section !== 'Subscription'

  return {
    tradingItem,
    isEcommerceProduct,
  }
}
